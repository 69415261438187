.kp-timelines-and-budgets {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    @include screen(tablet) {
      row-gap: 40px;
    }
    @include screen(laptop) {
      row-gap: 80px;
    }
  }
  &__start,
  &__end {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  &__start {
    h3 {
      background-color: $blue-100;
      color: $blue-600;
      body.dark & {
        background-color: $blue-900;
        color: $white;
      }
    }
  }
  &__end {
    h3 {
      background-color: $teal-100;
      color: $teal-600;
      body.dark & {
        background-color: $teal-900;
        color: $white;
      }
    }
  }
  &__scheme {
    display: flex;
    gap: 64px;
    justify-content: center;
    margin: 0 auto;
    max-width: 289px;
    h3 {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      transition: color $transition-default, background-color $transition-default;
      @include font-2xl;
    }
    span {
      color: $gray-500;
      @include font-xs;
    }
  }
  &__arrow {
    margin-top: 24px;
    svg {
      transition: fill $transition-default;
      fill: $gray-900;
      body.dark & {
        fill: $white;
      }
    }
  }
  &__slogan {
    text-align: center;
    max-width: 832px;
    margin: 0 auto;
    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-lg;
    br {
      display: none;
      @include screen(laptop) {
        display: block;
      }
    }
    @include screen(laptop) {
      @include font-2xl;
    }
  }
  &__blocks {
    display: flex;
    justify-content: space-between;
    max-width: 832px;
    gap: 32px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    @include screen(tablet) {
      gap: 64px;
      flex-direction: row;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $gray-200;
    background-color: $gray-050;
    padding: 24px;
    @include screen(tablet) {
      padding: 48px;
      flex: 1;
    }
    transition: background-color $transition-default,
      border-color $transition-default, transform $transition-default;
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
    }
  }
  &__title {
    font-weight: 700;
    transition: color $transition-default;
    white-space: nowrap;
    body.dark & {
      color: $white;
    }
    @include font-3xl;
  }
  &__description {
    color: $gray-400;
  }
  &__link {
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    color: $blue-600;
  }
  &__table {
    max-width: 1000px;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    tr th:first-child,
    tr td:first-child {
      @include screen(laptop) {
        border-left: 1px solid $gray-200;
        body.dark & {
          border-color: $gray-700;
        }
      }
    }
    tr th:last-child,
    tr td:last-child {
      @include screen(laptop) {
        border-right: 1px solid $gray-200;
        body.dark & {
          border-color: $gray-700;
        }
      }
    }
    tr:first-child th:first-child {
      border-top-left-radius: 6px;
    }
    tr:first-child th:last-child {
      border-top-right-radius: 6px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
    thead {
      display: none;
      @include screen(laptop) {
        display: table-header-group;
      }
    }
    td {
      border-bottom: 1px solid $gray-200;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      text-align: right;
      transition: background-color $transition-default,
        border-color $transition-default, color $transition-default;
      body.dark & {
        border-color: $gray-700;
      }
      &:before {
        content: attr(data-label);
        text-align: left;
        font-weight: 600;
        text-transform: uppercase;
        grid-row: span 2 / span 2;
        grid-column: span 1 / span 1;
        display: flex;
        align-items: center;
        @include font-xs;
      }
      &:last-child {
        border-bottom: 0;
        @include screen(laptop) {
          border-bottom: 1px solid $gray-200;
          body.dark & {
            border-color: $gray-700;
          }
        }
      }
      @include screen(laptop) {
        height: 67px;
        text-align: left;
        display: table-cell;
        &:before {
          display: none;
        }
      }
      p {
        grid-column: span 1 / span 1;
        @include font-sm;
        line-height: 150%;
      }
      span {
        font-size: 10px;
        color: $gray-400;
        @include font-xs;
      }
      strong {
        font-weight: 600;
      }
    }
    tr {
      display: block;
      margin-bottom: 16px;
      border: 1px solid $gray-200;
      background-color: $gray-050;
      transition: background-color $transition-default,
        border-color $transition-default, transform $transition-default;
      border-radius: 8px;
      body.dark & {
        color: $white;
        border-color: $gray-700;
        background-color: $gray-800;
      }
      @include screen(tablet) {
        flex: 1;
      }
      @include screen(laptop) {
        display: table-row;
        padding: 48px;
        margin: 0;
        border-radius: 0;
      }
    }
    th,
    td {
      padding: 16px;
    }
    th {
      padding: 16px;
      background-color: $gray-200;
      border-top: 1px solid $gray-200;
      text-transform: uppercase;
      font-weight: 600;
      text-align: left;
      transition: background-color $transition-default,
        border-color $transition-default, color $transition-default;
      @include font-xs;
      body.dark & {
        background-color: $gray-700;
        border-color: $gray-700;
      }
    }
  }
}