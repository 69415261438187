.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;

  border: 1px solid $gray-200;
  border-radius: 8px;
  background-color: $gray-050;

  transition: transform $transition-default,
    background-color $transition-default, border-color $transition-default;
  body.dark & {
    border-color: $gray-700;
    background-color: $gray-800;
    // @include screen(laptop) {
    //   &:hover {
    //     transform: translateY(-8px);
    //   }
    // }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 48px;
    height: 48px;

    border-radius: 8px;
    background-color: $blue-600;

    transition: background-color $transition-default;
    body.dark & {
      background-color: $blue-900;
      svg {
        fill: $blue-900;
      }
    }
    svg {
      fill: $blue-600;

      color: $white;

      transition: fill $transition-default;
    }
  }
  &__title {
    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-xl;
  }
  &__description {
    margin-top: 8px;

    font-weight: 300;
    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-base;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  &__price {
    color: $blue-600;
    @include font-base;
  }
  &__arrow {
    svg {
      stroke: $blue-600;
    }
  }
  // @include screen(laptop) {
  //   &:hover {
  //     transform: translateY(-8px);
  //   }
  // }
}