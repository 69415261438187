.competence {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 24px;

  border: 1px solid $gray-200;
  border-radius: 8px;
  background-color: $gray-050;

  transition: border-color $transition-default,
    background-color $transition-default;
  body.dark & {
    border-color: $gray-700;
    background-color: $gray-800;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;

    border-radius: 8px;
    background-color: $blue-600;

    transition: background-color $transition-default;
    body.dark & {
      background-color: $blue-900;
    }
    svg {
      width: 24px;
      height: 24px;

      fill: transparent;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__title {
    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-xl;
  }
  &__text {
    max-width: 248px;
    min-height: 96px;

    font-weight: 300;
    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-base;
    @include screen(laptop) {
      max-width: 100%;
    }
  }
}