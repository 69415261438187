.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  width: 100%;

  transition: background-color $transition-default,
    box-shadow $transition-default;
  &.scrolled {
    box-shadow: 0 0 8px rgba($black, 0.1);
    background-color: $white;
    body.dark & {
      box-shadow: 0 0 8px rgba($black, 0.3);
      background-color: $gray-900;
    }
  }
  &__logo {
    svg {
      path {
        transition: fill $transition-default;
      }
      rect {
        transition: stroke $transition-default;
      }
      body.dark & {
        path {
          fill: $white;
        }
        rect {
          stroke: $white;
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    .nav {
      position: fixed;
      bottom: 0;
      left: 0;
      opacity: 0;

      z-index: -1;
      visibility: hidden;

      width: 100%;
      height: calc(100% - 62px);

      background-color: $white;

      &.active {
        opacity: 1;

        z-index: 10;
        visibility: visible;
      }
      body.dark & {
        background-color: $gray-900;
        @include screen(laptop) {
          background-color: transparent;
        }
      }
      .wrapper {
        @include screen(laptop) {
          padding: 0;
        }
      }
      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 16px;
        padding-top: 32px;

        transition: border-color $transition-default;

        @include screen(laptop) {
          flex-direction: row;
          align-items: center;
          row-gap: 0;
          column-gap: 56px;
          padding-top: 0;
          padding-right: 24px;

          border-right: 1px solid $gray-900;
          body.dark & {
            border-color: $gray-400;
          }
        }
      }
      @include screen(laptop) {
        transition: opacity 0, visibility 0, z-index 0;
      }
      @include screen(laptop) {
        position: static;
        z-index: 1;

        width: auto;
        height: calc(100% - 80px);

        background-color: transparent;
        opacity: 1;
        visibility: visible;
      }
    }
    @include screen(laptop) {
      height: 80px;
    }
  }
  &__phone {
    // &__email {
    display: none;
    margin-left: 32px;

    font-weight: 500;

    transition: color $transition-default;
    body.dark & {
      color: $white;
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
        }
      }
    }
    html.ru & {
      @include screen(tablet) {
        display: block;
      }
    }
    @include screen(laptop) {
      @include font-base;
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
        }
      }
    }
  }
  // &__email {
  //   display: none;
  //   html.en & {
  //     @include screen(tablet) {
  //       display: block;
  //     }
  //   }
  //   @include font-base;
  // }
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }
  &__mobile {
    // &__envelope {
    display: none;
    margin-left: 16px;
    svg {
      fill: transparent;
      path {
        transition: stroke $transition-default;
        body.dark & {
          stroke: $white;
        }
      }
    }
    html.ru & {
      display: block;
      @include screen(tablet) {
        display: none;
      }
    }
    @include screen(tablet) {
      display: none;
    }
  }
  // &__envelope {
  //   html.en & {
  //     display: block;
  //     @include screen(tablet) {
  //       display: none;
  //     }
  //   }
  // }
  &__call {
    margin: 2rem auto 0 auto;

    font-weight: 500;
    color: $blue-600;
    @include font-xl;
    @include screen(laptop) {
      display: none;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    column-gap: 6px;

    font-weight: 500;

    transition: color $transition-default;
    body.dark & {
      color: $white;
      &.active {
        color: $blue-600;
      }
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
        }
      }
    }
    span,
    &.active {
      color: $blue-600;
    }
    @include font-xl;
    @include screen(laptop) {
      @include font-base;
    }
    @include screen(laptop) {
      &:hover {
        color: $blue-600;
      }
    }
  }
  &__theme {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    &-light,
    &-dark {
      position: absolute;

      transition: opacity $transition-default, visibility $transition-default,
        z-index $transition-default;
    }
    &-light {
      opacity: 1;

      z-index: 1;
      visibility: visible;
    }
    &-dark {
      opacity: 0;
      visibility: hidden;

      z-index: -1;
    }
    &.active {
      .header {
        &__theme {
          &-light {
            opacity: 0;

            z-index: -1;
            visibility: hidden;
          }
          &-dark {
            opacity: 1;

            z-index: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  &__controls {
    position: relative;

    display: flex;
    align-items: center;
    column-gap: 24px;
    padding-left: 24px;
    @include screen(laptop) {
      display: flex;
    }
  }
  // &__language {
  //   display: flex;
  //   align-items: center;
  //   column-gap: 4px;
  //   img {
  //     min-width: 20px;

  //     border: 0.1px solid rgba($black, 0.1);
  //     border-radius: 2px;
  //   }
  //   span {
  //     display: block;

  //     transition: color $transition-default;
  //     @include font-sm;
  //   }

  //   &.active {
  //     span {
  //       color: $blue-600;
  //     }
  //   }
  // }
  &__burger {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 8px;
    width: 32px;
    height: 32px;
    svg {
      width: 14px;
      height: 14px;
      path,
      rect {
        transition: transform $transition-default, opacity $transition-default;

        body.dark & {
          fill: $white;
        }
      }
    }
    &.active {
      svg {
        rect {
          &:nth-child(1) {
            transform: translateX(3px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
        }
        path {
          &:nth-child(3) {
            transform: translateX(-7px) translateY(3px) rotate(-45deg);
          }
        }
      }
    }
    @include screen(laptop) {
      display: none;
    }
  }
}