.technologies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  &__title {
    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-2xl;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}