.success-alert {
  position: fixed;
  right: 20px;
  bottom: 20px;

  padding: 12px 24px;

  border-radius: 8px;
  background-color: $green-400;

  transition: transform $transition-default;
  transform: translateX(calc(100% + 40px));
  &__text {
    color: $white;
  }
  &.active {
    transform: translateX(0);
  }
}