.content {
  p {
    margin-top: 24px;
    body.dark & {
      color: $white;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  ol {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
    & > li {
      h3 {
        margin-bottom: 16px;

        font-weight: 600;

        transition: color $transition-default;
        body.dark & {
          color: $white;
        }
        @include font-xl;
        @include screen(tablet) {
          margin-bottom: 0;
        }
      }
      ul {
        li {
          position: relative;

          display: flex;
          margin-top: 12px;
          padding-left: 18px;

          transition: color $transition-default;
          &:first-child {
            margin-top: 0;
          }
          body.dark & {
            color: $white;
          }
          &:before {
            content: "";

            position: absolute;
            top: 8px;
            left: 0;

            width: 6px;
            height: 6px;

            border-radius: 50%;
            background-color: $blue-600;
            @include screen(tablet) {
              top: 10px;
            }
          }
          @include screen(tablet) {
            @include font-lg;
          }
        }
      }
      @include screen(tablet) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @include screen(laptop) {
        grid-template-columns: 1fr 646px;
      }
    }
    @include screen(tablet) {
      row-gap: 64px;
      margin-top: 64px;
    }
    @include screen(laptop) {
      margin-top: 80px;
    }
  }
}