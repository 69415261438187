.services {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 32px;
    @include screen(tablet) {
      row-gap: 40px;
    }
    @include screen(laptop) {
      row-gap: 64px;
    }
  }
  &__title {
    margin-bottom: 32px;
    @include font-2xl;
    @include screen(tablet) {
      @include font-3xl;
    }
    @include screen(laptop) {
      @include font-4xl;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 24px;
    width: 100%;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include screen(laptop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 32px;
    }
  }
}