* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

textarea,
input,
button,
select,
a {
  outline-style: none;
  border-radius: 0;

  font-weight: 400;
  font-family: "Inter", sans-serif;
  touch-action: manipulation;
}

html,
body {
  background-color: $white;

  font-family: "Inter", sans-serif;
  color: $gray-900;

  transition: background-color $transition-default;
  &.dark {
    background-color: $gray-900;
  }
}

main {
  overflow-x: hidden;
}

section {
  width: 100%;
}

.swiper {
  padding-bottom: 48px;
  &-pagination {
    margin-top: 32px;
    &-bullet {
      margin: 0 4px;
      opacity: 1;

      background-color: $gray-300;

      transition: background-color $transition-default;
      &-active {
        background-color: $blue-600;
      }
    }
    @include screen(tablet) {
      display: none;
    }
  }
  @include screen(tablet) {
    padding-bottom: 0;
  }
}

.render {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100%;

  background-color: $white;
}

.page-wrapper {
  padding-top: 62px;
  width: 100%;
  @include screen(laptop) {
    padding-top: 80px;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @include screen(tablet) {
    min-height: calc(100vh - 605px);
  }
  @include screen(laptop) {
    min-height: calc(100vh - 501px);
  }
}

.pusher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  &-centered {
    align-items: center;
  }
  @include screen(tablet) {
    row-gap: 40px;
  }
  @include screen(laptop) {
    row-gap: 64px;
  }
  @include screen(desktop) {
    row-gap: 48px;
  }
}

.gapper {
  padding: 32px 0;
  @include screen(tablet) {
    padding: 40px 0;
  }
  @include screen(laptop) {
    padding: 64px 0;
  }
}

.gapper-top {
  padding: 32px 0;
  @include screen(tablet) {
    padding: 40px 0 0;
  }
  @include screen(laptop) {
    padding: 80px 0 0;
  }
}

.gapper-bottom {
  padding: 32px 0;
  @include screen(tablet) {
    padding: 0 0 40px;
  }
  @include screen(laptop) {
    padding: 0 0 80px;
  }
}

.wrapper {
  margin: 0 auto;
  padding: 0 #{$container-padding};
  max-width: calc(#{$mobile-wrapper} + #{$container-padding * 2});
  @include screen(tablet) {
    max-width: calc(#{$screen-tablet} + #{$container-padding * 2});
  }
  @include screen(laptop) {
    max-width: calc(#{$screen-laptop} + #{$container-padding * 2});
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}