.not-found {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include screen(tablet) {
      gap: 40px;
    }
  }
  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__title {
    font-weight: 700;
    color: $blue-600;
    @include font-3xl;
    @include screen(tablet) {
      @include font-4xl;
    }
  }
  &__description {
    text-align: center;
    color: $gray-500;
    @include font-lg;
    @include screen(tablet) {
      @include font-2xl;
    }
  }
  &__links {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    margin: 0 auto;
    width: 100%;
    max-width: 492px;
    @include screen(tablet) {
      gap: 40px;
    }
    @include screen(laptop) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      max-width: 1024px;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 24px;

    border: 1px solid $gray-200;
    border-radius: 8px;
    background-color: $white;

    transition: background-color $transition-default,
      border-color $transition-default;
    body.dark & {
      border-color: $gray-800;
      background-color: $gray-800;
      @include screen(laptop) {
        &:hover {
          background-color: $gray-700;
        }
      }
    }
    span {
      color: $gray-500;

      transition: color $transition-default;
      body.dark & {
        color: $gray-400;
      }
      @include font-lg;
    }
    @include screen(laptop) {
      &:hover {
        background-color: $gray-050;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;

    border-radius: 8px;

    transition: background-color $transition-default;
    svg {
      path {
        transition: fill $transition-default, stroke $transition-default;
      }
    }
    &-blue {
      background-color: $blue-100;
      body.dark & {
        background-color: $blue-900;
        svg {
          path {
            fill: $blue-400;
          }
        }
      }
    }
    &-teal {
      background-color: $teal-100;
      body.dark & {
        background-color: $teal-900;

        svg {
          path {
            stroke: $teal-400;
          }
        }
      }
    }
    &-green {
      background-color: $teal-100;
      body.dark & {
        background-color: $green-900;
        svg {
          path {
            stroke: $green-400;
          }
        }
      }
    }
    &-purple {
      background-color: $purple-100;
      body.dark & {
        background-color: $purple-900;
        svg {
          path {
            stroke: $purple-400;
          }
        }
      }
    }
  }
}