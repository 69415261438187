.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  padding: 12px 20px;

  border-radius: 8px;
  &-bg {
    &-blue {
      background-color: $blue-600;

      color: $white;
      @include screen(laptop) {
        transition: background-color $transition-default;
        &:hover {
          background-color: $blue-700;
        }
      }
    }
  }
  &-with-icon {
    svg {
      margin-bottom: -3px;

      color: $white;
    }
  }
  &-centered {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @include font-base;
}