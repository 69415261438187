.headline,
.subheadline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.subheadline {
  grid-column: span 1;
  @include screen(tablet) {
    grid-column: span 2;
  }
  @include screen(laptop) {
    grid-column: span 3;
  }
}