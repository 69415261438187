.kp-targets {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 32px;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include screen(laptop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}