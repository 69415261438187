.subtitle {
  font-weight: 700;

  transition: color $transition-default;
  body.dark & {
    color: $white;
  }
  @include font-3xl;
  @include screen(tablet) {
    @include font-4xl;
  }
}