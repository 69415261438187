.cookie {
  position: fixed;
  bottom: 0;
  z-index: 200;

  padding: 16px 0;

  box-shadow: 0 0 8px rgba($black, 0.1);
  background-color: $white;

  transition: background-color $transition-default;
  body.dark & {
    box-shadow: 0 0 8px rgba($black, 0.3);
    background-color: $gray-900;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @include screen(tablet) {
      flex-direction: row;
    }
  }
  &__button {
    padding: 8px 12px;

    border-radius: 8px;
    background-color: $blue-600;

    white-space: nowrap;
    color: $white;
    @include font-sm;
  }
  &__text {
    max-width: 955px;

    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    span {
      color: $gray-900;

      transition: color $transition-default;
      body.dark & {
        color: $white;
      }
    }
    @include font-sm;
  }
  @include screen(laptop) {
    padding: 9px 0;
  }
}