.about {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px;
    @include screen(tablet) {
      row-gap: 40px;
    }
    @include screen(laptop) {
      row-gap: 64px;
    }
    @include screen(laptop) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: flex-start;
      column-gap: 64px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    @include screen(tablet) {
      row-gap: 24px;
    }
  }
  &__text {
    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-base;
    @include screen(tablet) {
      @include font-lg;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
    margin: 0 auto;
    padding: 16px;
    width: 100%;
    max-width: 1024px;

    border: 1px solid $gray-200;
    border-radius: 8px;

    transition: background-color $transition-default, border-color $transition-default;
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
    }
    @include screen(tablet) {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      row-gap: 0;
      padding: 24px 16px;
    }
    @include screen(laptop) {
      grid-column: span 2;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: border-color $transition-default;
    body.dark & {
      border-color: $gray-700;
    }
    &:last-child {
      border-right: 0;
    }
    @include screen(tablet) {
      border-right: 1px solid $gray-200;
    }
  }
  &__subtitle {
    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-3xl;
  }
  &__subtext {
    color: $gray-400;
  }
}