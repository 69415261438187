.kp-process {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-content: flex-start;
  row-gap: 32px;
  &-reversed {
    .kp-process__content{
      @include screen(laptop) {
        order: 2;
      }
    }
  }
  &__image {
    grid-column: span 1;
    height: 100%;
    min-height: 193px;
    aspect-ratio: 644 / 544;
    overflow: hidden;
    width: 100%;

    border: 1px solid $gray-200;

    border-radius: 8px;

    transition: border-color $transition-default;

    body.dark & {
      border-color: transparent;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
    @include font-4xl;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }
  &__content {
    display: flex;
    grid-column: span 1;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }
  &__title {
    font-weight: 700;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-3xl;
    @include screen(laptop) {
      @include font-4xl;
    }
  }
  &__external {
    display: flex;
    align-items: center;
    column-gap: 12px;

    font-weight: 600;
    color: $blue-600;

    transition: color $transition-default;
    @include font-lg;
    @include screen(laptop) {
      &:hover {
        color: $blue-700;
      }
    }
  }
  &__text {
    max-width: 536px;

    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-base;
    @include screen(tablet) {
      @include font-lg;
    }
    span {
      color: $blue-600;
    }
  }
  &__icons {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  &__button {
    svg {
      stroke: $white;
    }
  }
  @include screen(laptop) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    row-gap: 0;
    column-gap: 100px;
  }
}