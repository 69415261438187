.vacancy {
  padding: 24px;

  border: 1px solid $gray-200;
  border-radius: 8px;
  background-color: $gray-050;

  transition: transform $transition-default,
    background-color $transition-default, border-color $transition-default;
  body.dark & {
    border-color: $gray-700;
    background-color: $gray-800;
    @include screen(laptop) {
      &:hover {
        transform: translateY(-8px);
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
  }
  &__title {
    font-weight: 700;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-3xl;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__description {
    color: $gray-400;
  }
  &__technologies {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__link {
    svg {
      stroke: $blue-600;
    }
  }
  @include screen(laptop) {
    &:hover {
      transform: translateY(-8px);
    }
  }
}