.stack {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    @include screen(tablet) {
      row-gap: 40px;
    }
    @include screen(laptop) {
      row-gap: 64px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
}