.kp-hero {
  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    @include screen(tablet) {
      padding: 40px 0;
    }
    @include screen(laptop) {
      padding: 0 0 80px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    z-index: 10;
    max-width: 600px;
    @include screen(tablet) {
      height: calc(100vh - 222px);
    }
    @include screen(laptop) {
      height: calc(100vh - 240px);
    }
  }
  &__subtitle {
    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-base;
    @include screen(tablet) {
      @include font-xl;
    }
  }
  &__title {
    font-weight: 700;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-4xl;
    @include screen(tablet) {
      @include font-6xl;
    }
  }
  &__button {
    margin-top: 8px;
    @include screen(tablet) {
      margin-top: 32px;
    }
  }
  &__illustration {
    position: absolute;

    display: none;
    margin-bottom: 120px;
    width: 999px;
    height: 886px;
    @include screen(laptop) {
      display: block;
    }
    body.dark & {
      opacity: 0.8;
    }
  }
  @include screen(tablet) {
    max-height: calc(100vh - 62px);
  }
  @include screen(laptop) {
    max-height: calc(100vh - 80px);
  }
}