.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  &-wider {
    .heading {
      &__text {
        max-width: 523px;
      }
    }
  }
  &-wide {
    .heading {
      &__text {
        max-width: 640px;
      }
    }
  }
  &-centered {
    align-items: center;
    .heading {
      &__title,
      &__text {
        text-align: center;
      }
    }
  }
  &__title {
    font-weight: 700;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-3xl;
    @include screen(tablet) {
      @include font-4xl;
    }
  }
  &__text {
    max-width: 488px;

    color: $gray-400;
    @include font-base;
    @include screen(tablet) {
      @include font-xl;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    gap: 12px;
    span {
      color: $blue-600;
      @include font-base;
    }
    svg {
      stroke: $blue-600;
      fill: transparent;
    }
  }
}