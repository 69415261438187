.process {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
  &-reversed {
    grid-template-columns: 400px 1fr;
    .process__content {
      order: 1;
      @include screen(tablet) {
        order: 2;
      }
    }
    .process__image {
      order: 2;
      @include screen(tablet) {
        order: 1;
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  &__title {
    width: 100%;

    font-weight: 600;
    text-align: center;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-xl;
    @include screen(tablet) {
      width: auto;

      text-align: left;
    }
    @include screen(laptop) {
      @include font-2xl;
    }
  }
  &__description {
    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    br {
      display: none;
    }
    @include font-base;
    @include screen(tablet) {
      @include font-lg;
    }
    @include screen(laptop) {
      br {
        display: block;
      }
    }
  }
  .process__image {
    width: 100%;
    max-width: 350px;

    transition: opacity $transition-default;
    opacity: 0.9;
    img {
      width: 100%;
    }
    body.dark & {
      opacity: 1;
    }
    @include screen(laptop) {
      max-width: 400px;
    }
  }
  @include screen(tablet) {
    flex-direction: row;
  }
  @include screen(laptop) {
    column-gap: 72px;
    padding: 0 128px;
  }
}