.footer {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px;
    padding: 32px 0;

    border-top: 1px solid $gray-200;

    transition: border-color $transition-default;
    body.dark & {
      border-color: $gray-700;
    }
    @include screen(tablet) {
      grid-template-columns: 200px 280px 200px;
      justify-content: space-between;
      row-gap: 32px;
      padding: 40px 0;
    }
    @include screen(laptop) {
      grid-template-columns: repeat(4, minmax(260px, 280px));
      justify-content: space-between;
      padding: 64px 0 48px;
    }
  }
  &__title {
    margin-bottom: 16px;

    font-weight: 600;
    text-transform: uppercase;

    transition: color $transition-default;

    body.dark & {
      color: $white;
    }
    &-spacer {
      display: none;
      @include screen(tablet) {
        display: block;
        min-height: 24px;
      }
    }
    @include font-base;
  }
  &__col {
    grid-column: span 1;
    &:nth-child(3) {
      margin-top: -16px;
      @include screen(tablet) {
        margin-top: 0;
      }
    }
    &:last-child {
      @include screen(tablet) {
        grid-column: span 3;
      }
      @include screen(laptop) {
        grid-column: auto;
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;
  }
  &__link {
    color: $gray-500;
    body.dark & {
      color: $gray-400;
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
        }
      }
    }
    @include font-base;
    @include screen(laptop) {
      transition: color $transition-default;
      &:hover {
        color: $blue-600;
      }
    }
  }
  &__copy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 24px;
    padding: 32px 0;
    width: 100%;

    border-top: 1px solid $gray-200;

    transition: border-color $transition-default;
    body.dark & {
      border-color: $gray-700;
    }
    @include screen(tablet) {
      flex-direction: row;
      align-items: center;
      padding: 48px 0;
    }
  }
  &__logo {
    svg {
      path {
        transition: fill $transition-default;
      }
      rect {
        transition: stroke $transition-default;
      }
      body.dark & {
        path {
          fill: $white;
        }
        rect {
          stroke: $white;
        }
      }
    }
  }
  &__icons {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  &__icon {
    width: 18px;
    height: 18px;
    svg {
      fill: $gray-400;
      transition: fill $transition-default, stroke $transition-default;
    }
    @include screen(laptop) {
      &:hover {
        svg {
          fill: $blue-600;
        }
      }
    }
    body.dark & {
      svg {
        fill: $gray-400;
      }
      @include screen(laptop) {
        &:hover {
          svg {
            fill: $blue-600;
          }
        }
      }
    }
    &-nofill {
      svg {
        stroke: $gray-400;
        fill: transparent;
      }
      @include screen(laptop) {
        &:hover {
          svg {
            stroke: $blue-600;
            fill: transparent;
          }
        }
      }
      body.dark & {
        svg {
          stroke: $gray-400;
          fill: transparent;
        }
        @include screen(laptop) {
          &:hover {
            svg {
              stroke: $blue-600;
              fill: transparent;
            }
          }
        }
      }
    }
  }
  &__text {
    order: 2;

    color: $gray-500;
    body.dark & {
      color: $gray-400;
    }
    p {
      display: inline;

      font-weight: 800;
      letter-spacing: 1.6px;
    }
    br {
      @include screen(tablet) {
        display: none;
      }
    }
    span {
      display: inline-block;
      margin-top: 16px;
      @include screen(tablet) {
        display: inline;
        margin-top: 0;
      }
    }
    @include font-base;
    @include screen(tablet) {
      position: absolute;
      left: 50%;

      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
}