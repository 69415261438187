.technology {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;

  border-radius: 6px;
  background-color: $gray-100;

  transition: background-color $transition-default;
  body.dark & {
    background-color: $gray-800;
  }
  span {
    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-base;
  }
  svg {
    transition: fill $transition-default;
    &.github {
      body.dark & {
        fill: $white;
      }
    }
    &.php {
      body.dark & {
        fill: $white;
      }
    }
  }
}