.title {
  font-weight: 700;

  transition: color $transition-default;
  body.dark & {
    color: $white;
  }
  @include font-3xl;
  @include screen(tablet) {
    @include font-4xl;
  }
}

.description {
  max-width: 500px;

  font-weight: 400;
  color: $gray-400;
  a {
    color: $blue-600;

    transition: color $transition-default;
    @include screen(laptop) {
      &:hover {
        color: $blue-700;
      }
    }
  }
  br {
    display: none;
    @include screen(tablet) {
      display: block;
    }
  }
  @include font-base;
}