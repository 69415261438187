.kp-target {
  padding: 32px 24px;

  border: 1px solid $gray-200;
  border-radius: 8px;
  background-color: $gray-050;

  transition: background-color $transition-default,
    border-color $transition-default, transform $transition-default;
  body.dark & {
    border-color: $gray-700;
    background-color: $gray-800;
  }
  &-blue {
    .kp-target__icon {
      background-color: $blue-100;
      body.dark & {
        background-color: $blue-900;
      }
      svg {
        stroke: $blue-600;
        body.dark & {
          stroke: $blue-400;
        }
      }
    }
    .kp-target__check {
      background-color: $blue-100;
      body.dark & {
        background-color: $blue-900;
      }
      svg {
        stroke: $blue-600;
        body.dark & {
          stroke: $blue-400;
        }
      }
    }
  }
  &-purple {
    .kp-target__icon {
      background-color: $purple-100;
      body.dark & {
        background-color: $purple-900;
      }
      svg {
        stroke: transparent;
        fill: $purple-600;
        body.dark & {
          fill: $purple-400;
        }
      }
    }
    .kp-target__check {
      background-color: $purple-100;
      body.dark & {
        background-color: $purple-900;
      }
      svg {
        stroke: $purple-600;
        body.dark & {
          stroke: $purple-400;
        }
      }
    }
  }
  &-teal {
    .kp-target__icon {
      background-color: $teal-100;
      body.dark & {
        background-color: $teal-900;
      }
      svg {
        stroke: $teal-600;
        body.dark & {
          stroke: $teal-400;
        }
      }
    }
    .kp-target__check {
      background-color: $teal-100;
      body.dark & {
        background-color: $teal-900;
      }
      svg {
        stroke: $teal-600;
        body.dark & {
          stroke: $teal-400;
        }
      }
    }
    @include screen(tablet) {
      grid-column: span 2;
    }

    @include screen(laptop) {
      grid-column: span 1;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 48px;
    height: 48px;

    border-radius: 8px;

    transition: background-color $transition-default;
    svg {
      fill: transparent;

      transition: stroke $transition-default, fill $transition-default;
    }
  }
  &__title {
    margin-bottom: 16px;

    font-weight: 600;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-xl;
  }
  &__text {
    font-weight: 300;
    color: $gray-500;

    transition: color $transition-default;
    body.dark & {
      color: $gray-400;
    }
    @include font-sm;
    @include screen(tablet) {
      @include font-base;
    }
  }
  &__check {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;

    border-radius: 50%;

    transition: background-color $transition-default;
    svg {
      stroke: transparent;
      fill: transparent;

      transition: stroke $transition-default, fill $transition-default;
    }
  }
  @include screen(laptop) {
    padding: 32px;
    &:hover {
      transform: translateY(-8px);
    }
  }
}