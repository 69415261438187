.form-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  &__block {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    @include screen(laptop) {
      display: grid;
      grid-template-columns: 752px 1fr;
      gap: 64px;
    }
  }
  &__form {
    width: 100%;
  }
  &__container {
    &:hover {
      & + .form-block__tip {
        opacity: 1;
      }
    }
  }
  &__tip {
    display: none;

    color: $gray-500;

    transition: color $transition-default, opacity $transition-default;
    opacity: 0;
    body.dark & {
      color: $gray-400;
    }
    ol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
      margin-top: 16px;
      margin-left: 20px;
      list-style: decimal;
      li {
        color: $gray-400;
        body.dark & {
          color: $gray-500;
        }
        @include font-base;
      }
    }
    @include font-base;
    @include screen(laptop) {
      display: block;
    }
  }
  &__icon {
    stroke: $gray-500;
    fill: transparent;
  }
  &__inputs {
    position: relative;

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 24px;
    }
  }
  &__label,
  &__textarea,
  &__select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__label {
    grid-column: span 2;
    @include screen(tablet) {
      grid-column: span 1;
    }
  }
  &__title {
    font-weight: 500;

    transition: color $transition-default;
    body.dark & {
      color: $white;
    }
    @include font-base;
  }
  &__textarea {
    grid-column: span 2;

    border-radius: 8px;
    textarea {
      resize: none;

      width: 100%;
      height: 166px;

      background-color: transparent;

      transition: color $transition-default;
      &::placeholder {
        color: $gray-500;

        transition: color $transition-default;
      }
      body.dark & {
        color: $white;
        &::placeholder {
          color: $gray-400;
        }
      }
      @include font-base;
    }
  }
  &__input {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px 16px;
    width: 100%;

    border: 1px solid $gray-300;
    border-radius: 8px;
    background-color: $gray-050;

    transition: border-color $transition-default,
      background-color $transition-default;
    &-hidden {
      position: absolute;
      opacity: 0;

      width: 0;
      height: 0;
    }
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
      &.error {
        border-color: $red-600;
      }
    }
    &.error {
      border-color: $red-600;
    }
    input {
      width: 100%;

      background-color: transparent;

      transition: color $transition-default;
      &::placeholder {
        color: $gray-500;

        transition: color $transition-default;
      }
      body.dark & {
        color: $white;
        &::placeholder {
          color: $gray-400;
        }
      }
      @include font-base;
    }
  }
  &__button {
    padding: 12px 20px;
    min-width: 220px;

    border-radius: 8px;
    background-color: $blue-600;

    font-weight: 500;
    color: $white;

    transition: background-color $transition-default,
      opacity $transition-default;
    &.disabled {
      background-color: $gray-700;
      pointer-events: none;
      opacity: 0.5;
    }
    @include font-base;
    @include screen(laptop) {
      &:hover {
        background-color: $blue-700;
      }
    }
  }
  &__policy {
    grid-column: span 2;
    max-width: 519px;

    color: $gray-400;

    transition: color $transition-default;
    a {
      color: $blue-600;

      transition: color $transition-default;
      @include screen(laptop) {
        &:hover {
          color: $blue-700;
        }
      }
    }
    body.dark & {
      color: $white;
    }
  }
  &__field {
    width: 100%;
  }
  &__header,
  &__footer {
    padding: 16px;

    border: 1px solid $gray-300;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: $gray-050;

    transition: border-color $transition-default,
      background-color $transition-default;
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 16px;
    column-gap: 16px;

    border-bottom: 1px solid $gray-300;
    border-radius: 0 0 8px 8px;
    @include screen(tablet) {
      flex-direction: row;
      align-items: center;
    }
  }
  &__file {
    position: relative;
    &.error {
      span {
        color: $red-600;
      }
      body.dark & {
        span {
          color: $red-600;
        }
      }
    }
    &.ok {
      span {
        color: $green-400;
      }
      body.dark & {
        span {
          color: $green-400;
        }
      }
    }
    label {
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 8px;
      span {
        font-weight: 500;
        color: $gray-500;

        transition: color $transition-default;
        body.dark & {
          color: $gray-400;
        }
        @include font-sm;
      }
      input {
        position: absolute;

        width: 0;
        height: 0;
        opacity: 0;
      }
      svg {
        min-width: 24px;
        min-height: 24px;
        body.dark & {
          fill: $gray-400;
        }
      }
    }
    &:hover {
      .form-block__alert {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__alert {
    position: absolute;
    top: -54px;

    padding: 6px 12px;
    width: 266px;

    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color: $white;

    color: $gray-400;

    transition: visibility $transition-default, opacity $transition-default,
      background-color $transition-default, box-shadow $transition-default;
    visibility: hidden;
    opacity: 0;
    body.dark & {
      box-shadow: none;
      background-color: $gray-700;
    }
    @include font-xs;
    @include screen(tablet) {
      right: 0;
      bottom: auto;
    }
    @include screen(laptop) {
      top: -4px;
      right: auto;
      bottom: auto;
      left: calc(100% + 8px);
    }
  }
  &__select {
    position: relative;

    grid-column: span 2;
  }
  &__selected {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 14px 16px;
    width: 100%;

    border: 1px solid $gray-300;
    border-radius: 8px;
    background-color: $gray-050;

    transition: border-color $transition-default,
      background-color $transition-default;
    cursor: pointer;
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
    }
    input {
      position: absolute;

      width: 0;
      height: 0;
      opacity: 0;
    }
    span {
      color: $gray-500;

      transition: color $transition-default;
      body.dark & {
        color: $gray-400;
      }
    }
    svg {
      position: absolute;
      right: 16px;

      stroke: $gray-500;

      transition: stroke $transition-default, transform $transition-default;
      body.dark & {
        stroke: $gray-400;
      }
    }
    &.selected {
      span {
        color: $gray-900;
        body.dark & {
          color: $white;
        }
      }
    }
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &__list {
    position: absolute;
    top: 96px;
    left: 8px;
    z-index: -1;

    display: flex;
    flex-direction: column;
    row-gap: 0;
    padding: 4px 16px;
    max-height: 212px;
    overflow-y: auto;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-300;
    border-radius: 8px;
    background-color: $gray-050;

    transition: border-color $transition-default,
      background-color $transition-default, opacity $transition-default,
      visibility $transition-default, z-index $transition-default;
    visibility: hidden;
    opacity: 0;
    body.dark & {
      border-color: $gray-700;
      background-color: $gray-800;
    }
    &.active {
      opacity: 1;
      visibility: visible;

      z-index: 2;
    }
  }
  &__services {
    width: 100%;
  }
  &__service {
    cursor: pointer;

    padding: 8px 0;

    transition: color $transition-default;
    body.dark & {
      color: $white;
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
        }
      }
    }
    @include screen(laptop) {
      &:hover {
        color: $blue-600;
      }
    }
  }
}