.cases {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    @include screen(tablet) {
      row-gap: 40px;
    }
    @include screen(laptop) {
      row-gap: 64px;
    }
  }
  &__items {
    width: 100%;
  }
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    @include screen(laptop) {
      row-gap: 80px;
    }
  }
  &__button {
    display: none;
    width: 100%;
    max-width: 246px;
    @include screen(laptop) {
      display: inline-flex;
    }
  }
}