.breadcrumbs {
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    svg {
      margin-top: -2px;
      margin-right: 16px;
      width: 14px;
      height: 14px;
    }
    a,
    span {
      display: inline-block;

      font-weight: 500;
      color: $gray-400;

      transition: color $transition-default;
      @include font-xs;
      @include screen(tablet) {
        @include font-sm;
      }
    }
    a {
      display: flex;
      align-items: center;

      svg {
        color: $gray-400;

        transition: color $transition-default;
      }
      @include screen(laptop) {
        &:hover {
          color: $blue-600;
          span {
            color: $blue-600;
          }
          svg {
            color: $blue-600;
          }
        }
      }
    }
  }
}